import { AltLayout } from '../layouts/AltLayout';
import Button from '@/common/button';
import { SEO } from '@/common/seo';

const PatientForms: Page = () => (
    <AltLayout
        heroImg="CareCreditHero.jpg"
        label="Patient Forms"
        title="Get ready for your visit"
        description={
            <>
                Download patient forms below:{' '}
                <div className="flex flex-row gap-x-2 pt-3">
                    <a href="/zapata-patient-form-english.pdf" target="_blank">
                        <Button
                            text="English"
                            className="bg-green border-none text-white mx-auto mb-12"
                        />
                    </a>
                    <a href="/zapata-patient-form-spanish.pdf" target="_blank">
                        <Button
                            text="Spanish"
                            className="bg-lightbrown border-none text-white mx-auto mb-12"
                        />
                    </a>
                </div>
            </>
        }
    ></AltLayout>
);

export default PatientForms;

export const Head = () => (
    <SEO
        title="Get Ready for your first visit - Patient Forms - Zapata Dental"
        description="Download patient forms to streamline your first visit."
        pathname="/patient-forms/"
    />
);
